/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
        childImageSharp {
          fixed(width: 400, height: 400, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            summary1
            name
            summary2
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const avatar = data?.avatar?.childImageSharp?.fixed

  return (
    <div className="bio">
      {avatar && (
        <Image
          fixed={avatar}
          alt={author?.name || ``}
          className="bio-avatar"
        />
      )}
      {author?.name && (
        <p className="about-text">
          Hi! I am Onni Anttoora, a designer interested in using design to make inhumane experiences more humane.
          <br/><br/>
          Currently, I am working as a UX designer in a startup focused on reducing food waste.
          <br/><br/>
          This site was created using Gatsby and is hosted on Render. Fonts are by <a href="https://ia.net/">iA.</a>
        </p>
      )}
     
    </div>
  )
}

export default Bio
